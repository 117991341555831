export const routeMappings = {
  // English to Arabic mappings
  '/': '/ar/',
  '/en/our-team': '/ar/our-team',
  '/en/courses': '/ar/courses',
  '/en/kids-courses': '/ar/kids-courses',
  '/en/adult-courses': '/ar/adult-courses',
  '/en/about-us': '/ar/about-us',
  '/en/join-us': '/ar/join-us',
  '/en/blog': '/ar/blog',
  '/en/contact': '/ar/contact',
  '/en/register': '/ar/register',
  '/en/login': '/ar/login',
  '/en/vision-mission': '/ar/vision-mission',
  '/en/study-abroad': '/ar/study-abroad',
  '/en/faq': '/ar/faq',
  '/en/accredations': '/ar/accredations',
  '/en/packages': '/ar/packages',
  '/en/package': '/ar/package',
  '/en/cart': '/ar/cart',
  '/en/checkout': '/ar/checkout',
  '/en/monglish-immerse': '/ar/monglish-immerse',
  '/en/monglish-promise': '/ar/monglish-promise',
  '/en/monglish-reasons': '/ar/monglish-reasons',
  '/en/certifications': '/ar/certifications',
  '/en/our-founder': '/ar/our-founder',
  '/en/youtube-videos': '/ar/youtube-videos',
  '/en/photo-gallery': '/ar/photo-gallery',
  '/en/ielts-courses': '/ar/ielts-courses',
  '/en/teachers': '/ar/teachers',
  '/en/teachers/one': '/ar/teachers/one',
  '/en/teachers/two': '/ar/teachers/two',
  '/en/teachers/three': '/ar/teachers/three',
  '/en/teachers/four': '/ar/teachers/four',
  '/en/teachers/five': '/ar/teachers/five',
  '/en/teachers/six': '/ar/teachers/six',
  '/en/teachers/eight': '/ar/teachers/eight',
  '/en/teachers/nine': '/ar/teachers/nine',
  '/en/teachers/ten': '/ar/teachers/ten',
  '/en/teachers/eleven': '/ar/teachers/eleven',
  '/en/teachers/twelve': '/ar/teachers/twelve',
  '/en/teachers/thirteen': '/ar/teachers/thirteen',
  '/en/teachers/fourteen': '/ar/teachers/fourteen',
  '/en/teachers/fifteen': '/ar/teachers/fifteen',  
  '/en/teachers/sixteen': '/ar/teachers/sixteen',
  '/en/teachers/seventeen': '/ar/teachers/seventeen',
  '/en/teachers/eighteen': '/ar/teachers/eighteen',
  '/en/teachers/nineteen': '/ar/teachers/nineteen',
  '/en/teachers/twenty': '/ar/teachers/twenty',
  '/en/teachers/twenty-one': '/ar/teachers/twenty-one',
  '/en/teachers/twenty-two': '/ar/teachers/twenty-two',
  '/en/teachers/twenty-three': '/ar/teachers/twenty-three',
  '/en/teachers/twenty-four': '/ar/teachers/twenty-four',
  '/en/teachers/twenty-five': '/ar/teachers/twenty-five',
  '/en/teachers/twenty-six': '/ar/teachers/twenty-six',
  '/en/teachers/twenty-seven': '/ar/teachers/twenty-seven',
  '/en/teachers/twenty-eight': '/ar/teachers/twenty-eight',
  '/en/teachers/twenty-nine': '/ar/teachers/twenty-nine',
  '/en/teachers/thirty': '/ar/teachers/thirty',

  // Arabic to English mappings
  '/ar/': '/',
  '/ar/our-team': '/en/our-team',
  '/ar/courses': '/en/courses',
  '/ar/kids-courses': '/en/kids-courses',
  '/ar/adult-courses': '/en/adult-courses',
  '/ar/about-us': '/en/about-us',
  '/ar/join-us': '/en/join-us',
  '/ar/blog': '/en/blog',
  '/ar/contact': '/en/contact',
  '/ar/register': '/en/register',
  '/ar/login': '/en/login',
  '/ar/vision-mission': '/en/vision-mission',
  '/ar/study-abroad': '/en/study-abroad',
  '/ar/faq': '/en/faq',
  '/ar/accredations': '/en/accredations',
  '/ar/packages': '/en/packages',
  '/ar/package': '/en/package',
  '/ar/cart': '/en/cart',
  '/ar/checkout': '/en/checkout',
  '/ar/monglish-immerse': '/en/monglish-immerse',
  '/ar/monglish-promise': '/en/monglish-promise',
  '/ar/monglish-reasons': '/en/monglish-reasons',
  '/ar/certifications': '/en/certifications',
  '/ar/our-founder': '/en/our-founder',
  '/ar/youtube-videos': '/en/youtube-videos',
  '/ar/photo-gallery': '/en/photo-gallery',
  '/ar/ielts-courses': '/en/ielts-courses',
  '/ar/teachers': '/en/teachers',
  '/ar/teachers/one': '/en/teachers/one',
  '/ar/teachers/two': '/en/teachers/two',
  '/ar/teachers/three': '/en/teachers/three',
  '/ar/teachers/four': '/en/teachers/four',
  '/ar/teachers/five': '/en/teachers/five',
  '/ar/teachers/six': '/en/teachers/six',
  '/ar/teachers/eight': '/en/teachers/eight',
  '/ar/teachers/nine': '/en/teachers/nine',
  '/ar/teachers/ten': '/en/teachers/ten',
  '/ar/teachers/eleven': '/en/teachers/eleven',
  '/ar/teachers/twelve': '/en/teachers/twelve',
  '/ar/teachers/thirteen': '/en/teachers/thirteen',
  '/ar/teachers/fourteen': '/en/teachers/fourteen',
  '/ar/teachers/fifteen': '/en/teachers/fifteen',  
  '/ar/teachers/sixteen': '/en/teachers/sixteen',
  '/ar/teachers/seventeen': '/en/teachers/seventeen',
  '/ar/teachers/eighteen': '/en/teachers/eighteen',
  '/ar/teachers/nineteen': '/en/teachers/nineteen',
  '/ar/teachers/twenty': '/en/teachers/twenty',
  '/ar/teachers/twenty-one': '/en/teachers/twenty-one',
  '/ar/teachers/twenty-two': '/en/teachers/twenty-two',
  '/ar/teachers/twenty-three': '/en/teachers/twenty-three',
  '/ar/teachers/twenty-four': '/en/teachers/twenty-four',
  '/ar/teachers/twenty-five': '/en/teachers/twenty-five',
  '/ar/teachers/twenty-six': '/en/teachers/twenty-six',
  '/ar/teachers/twenty-seven': '/en/teachers/twenty-seven',
  '/ar/teachers/twenty-eight': '/en/teachers/twenty-eight',
  '/ar/teachers/twenty-nine': '/en/teachers/twenty-nine',
  '/ar/teachers/thirty': '/en/teachers/thirty'
};
