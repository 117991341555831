<template>
  <div class="accre-sec3-en">
    <div class="content-right-sec3-en">
      <h2>Information About <br> The Organization IOTQ - USA</h2>
      <div class="certificates-acc">
        <img loading="lazy" src="https://monglish.b-cdn.net/accreditations/7.webp" alt="ARQOA" />
      </div>
      <p>The International Organization for Training Quality - USA (IOTQ USA) is an independent American organization
        that supports and advances trainees and professional trainers, serving as a specialized entity in ensuring
        training quality. Amid the challenges of COVID-19, IOTQ USA aims to address concerns related to the training
        sector by maintaining and improving training standards</p>
      <h6>*Reference: IOTQ USA website.</h6>
    </div>
    <div class="content-left-sec3-en">
      <img loading="lazy" src="https://monglish.b-cdn.net/home/acc/sec3.jpg" alt="certficate">
    </div>
  </div>
</template>

<script>
export default {
}
</script>

<style scoped>
.accre-sec3-en {
  background-image: url('https://monglish.b-cdn.net/home/acc/acc-sec3.jpg');
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  direction: ltr;
  flex-wrap: wrap;
}

.certificates-acc img {
  width: 80px;
}

.content-right-sec3-en {
  width: 100%;
  max-width: 60%;
  z-index: 1;
  position: relative;
  font-family: 'DIN Next LT Arabic', sans-serif;
  font-weight: 500;
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.content-right-sec3-en h2 {
  padding-top: 10%;
  font-size: 3em;
  font-family: 'DIN Next LT Arabic-n';
  font-weight: 700;
}

.content-right-sec3-en p {
  font-size: 1.4em;
  margin-bottom: 10px;
}

.content-right-sec3-en h6 {
  font-size: 0.8rem;
}

.content-left-sec3-en {
  width: 100%;
  max-width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
  background-color: transparent;
  box-sizing: border-box;
}

.content-left-sec3-en img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 0px 0px 25px 25px;
}

.accre-button-en {
  width: 30%;
  padding: 10px;
  background: linear-gradient(45deg, #fe9b4f, #f47e23);
  color: white;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-bottom: 1em;
  margin-top: 1em;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .accre-sec3-en {
    flex-direction: column;
    height: auto;
  }

  .content-right-sec3-en {
    max-width: 100%;
    padding: 10px;
  }

  .content-left-sec3-en {
    max-width: 100%;
    padding: 10px;
  }

  .accre-button-en {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .content-right-sec3-en h2 {
    font-size: 1.5rem;
    text-align: center;
  }

  .content-right-sec3-en p {
    font-size: 0.9rem;
    text-align: center;
  }

  .content-right-sec3-en h6 {
    font-size: 0.7rem;
    text-align: center;
  }

  .accre-button-en {
    font-size: 0.8rem;
    width: 50%;
    display: block;
    margin: 0 auto;
    margin-top: 20px;
  }

  .certificates-acc img {
    margin: 0 auto;
    padding: 1em;
    width: 40%;
  }
}
</style>
